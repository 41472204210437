import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Hero from "../components/hero"
import { SHOW_1, SHOW_2, SHOW_3, SHOW_4, SHOW_5, SHOW_6, SHOW_7 } from "../components/images"
import Gallery from "../components/gallery"

const images = [
  { src: SHOW_5.src, thumbnail: SHOW_5.src, alt: SHOW_5.alt, orientation: "landscape" },
  { src: SHOW_1.src, thumbnail: SHOW_1.src, alt: SHOW_1.alt, orientation: "landscape" },
  { src: SHOW_7.src, thumbnail: SHOW_7.src, alt: SHOW_7.alt, orientation: "landscape" },
  { src: SHOW_2.src, thumbnail: SHOW_2.src, alt: SHOW_2.alt, orientation: "landscape" },
  { src: SHOW_6.src, thumbnail: SHOW_6.src, alt: SHOW_6.alt, orientation: "landscape" },
  { src: SHOW_4.src, thumbnail: SHOW_4.src, alt: SHOW_4.alt, orientation: "landscape" },
  { src: SHOW_3.src, thumbnail: SHOW_3.src, alt: SHOW_3.alt, orientation: "landscape" },
]

const theme = {
  container: {
    background: "rgba(255, 255, 255, 0.9)",
  },
  // arrows
  arrow: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    fill: "#222",
    opacity: 0.6,
    transition: "opacity 200ms",

    ":hover": {
      opacity: 1,
    },
  },
  arrow__size__medium: {
    borderRadius: 40,
    height: 40,
    marginTop: -20,

    "@media (min-width: 768px)": {
      height: 70,
      padding: 15,
    },
  },
  arrow__direction__left: { marginLeft: 10 },
  arrow__direction__right: { marginRight: 10 },
  close: {
    fill: "#D40000",
    opacity: 0.6,
    transition: "all 200ms",
    ":hover": {
      opacity: 1,
    },
  },

  // footer
  footer: {
    color: "black",
  },
  footerCount: {
    color: "rgba(0, 0, 0, 0.6)",
  },

  // thumbnails
  thumbnail: {},
  thumbnail__active: {
    boxShadow: "0 0 0 2px #00D8FF",
  },
}

class GraphPage extends React.Component {

  state = {
    photoIndex: 0,
    isOpen: true,
    lightboxIsOpen: true,
  }

  render() {
    const { photoIndex, lightboxIsOpen } = this.state
    return (
      <div>
        <Header/>

        <Hero small>&nbsp;</Hero>

        <div>
          <section className="article-wrapper">
            <div className="container">
              <div className="row">


                <div className="col-md-7 col-sm-9" style={{ marginBottom: 100 }}>
                  <div className="article-single">
                    <div className="text-block">
                      <h4 style={{ fontWeight: 900 }}>
                        Η πρόταση
                      </h4>
                    </div>
                    <hr/>
                    <h3 className="alt-font uppercase">Περιληψη προτασης</h3>

                    <p>
                      Η ταχύτατη τεχνολογική εξέλιξη της νέας-τρίτης γενιάς του διαδικτύου, που έχει σαν
                      βάση του τη διασύνδεση στον παγκόσμιο ιστό φυσικών αντικειμένων (IoT - Internet
                      of things), προϋποθέτει την ανάπτυξη αισθητήρων που θα μπορούν να
                      ενσωματώνονται σε αντικείμενα στο φυσικό χώρο προσδίδοντας τους έτσι
                      δυνατότητες αλληλεπίδρασης με το περιβάλλον τους. Έναν τέτοιο ρόλο
                      αναμένεται να παίξουν πολύ σύντομα και τα κυκλώματα RFID
                      (RadioFrequencyIdentification) δηλ. στοιχεία αναγνώρισης / σήμανσης στην περιοχή
                      των ραδιοσυχνοτήτων (840-960 ΜΗz / 13.56 ΜΗz).
                    </p>

                    <p>
                      Το προτεινόμενο έργο στοχεύει
                      στην ανάπτυξη συστήματος
                      εκτύπωσης σε χαρτί εύκαμπτων
                      και ανθεκτικών κυκλωμάτων
                      RFID με βάση το μελάνι
                      γραφενίου. Κατά την
                      διάρκεια του έργου θα
                      αναπτυχθεί απλή μέθοδος
                      παραγωγής μελανιού γραφενίου, ώστε να μπορεί να χρησιμοποιηθεί στην
                      εκτύπωση τύπου screen (μεταξοτυπία).
                    </p>

                    <p>
                      H ποιότητα και τα χαρακτηριστικά του
                      μελανιού θα επιτρέπουν την εκτύπωση κυκλωμάτων σε συγκεκριμένο τύπο χαρτιού,
                      με δομικά και ηλεκτρικά χαρακτηριστικά, έτσι ώστε να μπορούν να
                      χρησιμοποιηθούν σε εκτύπωση κεραιών RFID. Με την λήξη του έργου, η επιχείρηση
                      θα είναι σε θέση να εκτυπώνει πρότυπες ετικέτες και κυκλώματα RFID/NFC με
                      απώτερο στόχο την χρήση τους σε συσκευασίας χάρτου και συγκεκριμένα σε
                      συσκευασίες φαρμάκων. Οι ετικέτες και τα κυκλώματα εσωτερικά τις
                      συσκευασίας θα σχεδιαστούν, έτσι ώστε να είναι σε θέση να παρέχουν
                      πληροφορίες για τον έλεγχο αυθεντικότητας των φαρμάκων – που αποτελεί ένα
                      από τα σοβαρότερα προβλήματα της φαρμακοβιομηχανίας, τις οδηγίες χρήσης και
                      τις παρενέργειες, ενώ θα επιτρέπουν τη σύνδεση με κατάλληλη ιατρική εφαρμογή
                      (tablet/mobile, υπολογιστής) για τον έλεγχο χορήγησης φαρμάκων υπό καθεστώς.

                    </p>

                    <h3 className="alt-font uppercase">Αντικειμενο και στοχοι</h3>
                    <p>
                      Σκοπός του συνόλου της πρότασης είναι η δημιουργία προηγμένων - «έξυπνων»
                      χάρτινων συσκευασιών για φαρμακευτικά σκευάσματα καθώς και μίας
                      ολοκληρωμένης γραμμής παραγωγής που θα υποστηρίζει την κατασκευή αυτών
                      στην Πάτρα.

                    </p>

                    <p>
                      Μετά το πέρας του έργου, να υπάρχει ένα προϊόν το οποίο να μπορεί
                      να διατεθεί άμεσα στην αγορά. Συγκεκριμένα εκτός από την ίδια τη διαδικασία
                      παραγωγής στην Πάτρα, θα μπορεί να υπάρχει υποστήριξη με βάση την
                      τεχνογνωσία που θα έχει αποκτηθεί στην on-site εγκατάσταση μίας εκτυπωτικής
                      μηχανής και ενσωμάτωσης στην ήδη υπάρχουσα εγκατάσταση οποιασδήποτε
                      φαρμακοβιομηχανίας.

                    </p>

                    <p>
                      Η ανάπτυξη ενός τέτοιου προϊόντος θα αποφέρει σημαντικά αποτελέσματα τόσο
                      για την περιοχή της Δυτικής Ελλάδας (φαρμακοβιομηχανίες) όσο και για το σύνολο του κλάδου στη χώρα
                      μας, αφού θα μπορεί να ανταγωνιστεί διεθνώς από ακόμη καλύτερη θέση με ένα άκρως καινοτόμο προϊόν.
                      Ταυτόχρονα στόχος αξιοποίησης είναι και μεγάλες βιομηχανίες φαρμάκου στην ΕΕ, οι οποίες θα
                      μπορέσουν
                      μέσω του παραγόμενου προϊόντος να μειώσουν την πειρατεία και το λαθρεμπόριο, ενώ θα προσφέρουν και
                      ένα
                      πλήρως σύγχρονοπροϊόν στους πελάτες τους.

                    </p>


                    <h3 className="alt-font uppercase">Τιτλος προτασης</h3>
                    <p>
                      Εκτύπωση και παραγωγή κυκλωμάτων τεχνολογίας ανταλλαγής δεδομένων από κοντινή απόσταση (NFC) με
                      μελάνια γραφενίου για έξυπνες συσκευασίες φαρμακευτικών σκευασμάτων

                    </p>
                  </div>
                </div>

                <div className="col-md-5">
                  <Gallery theme={theme} images={images} showThumbnails/>
                </div>
              </div>
            </div>
          </section>
        </div>


        <Footer/>
      </div>
    )
  }
}

export default GraphPage